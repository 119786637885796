import { useEffect, useState } from "react";
import Card from "../components/card/Card";
import ProgressBar from "../components/progressBar/ProgressBar";
import { APP_API_URL } from "../utils/api";
import { useQueries } from "../utils/query";
import { useAuth } from "../hooks/useAuth";
import { UserRoles } from "../config/roles";
import BoxIcons from "../components/icons/BoxIcons";
import { BadgeRounded } from "../components/badge/Badge";
import Table from "../components/table/Table";
import { ColumnInterface } from "../components/table/typings";

function DashboardPage() {
    const { user: auth } = useAuth();
    const [filterBalances, setFilterBalances] = useState<any[]>([]);
    const [filterSessions, setFilterSessions] = useState<any[]>([]);
    const {
        data: balances,
        isLoading
    } = useQueries(APP_API_URL + `balances`, ["balances"]);

    const {
        data: sessions,
        isLoading: sessionsIsloading
    } = useQueries(APP_API_URL + `${auth.roles.name === UserRoles.ADMIN ? 'session-user-actives' : 'session-actives'}`, ["sessions-actives"]);

    const getPercent = (credit: number, balance: number): number => {
        return parseInt(((credit / balance) * 100).toFixed(0));
    }

    const ChooseProgressBar = (credit: any, balance: any) => {
        const percent = parseInt(((credit / balance) * 100).toFixed(0));
        let className = "";
        if (percent >= 75) {
            className = "bg-success";
        }
        else if (percent < 75 && percent > 50) {
            className = "bg-primary";
        }
        else if (percent < 50 && percent > 25) {
            className = "bg-warning";
        }
        else {
            className = "bg-danger";
        }
        return <ProgressBar className={className} striped={true} value={percent} />
    }

    const handleFilterBalance = (search: string) => {
        if (search !== "") {
            setFilterBalances(balances?.data.data.filter((balance: any) => balance.country.name.toLowerCase().includes(search.toLowerCase()) || balance.type.toLowerCase().includes(search.toLowerCase())))
        } else {
            setFilterBalances(balances?.data.data)
        }
    }

    const handleFilterSession = (search: string) => {
        if (search !== "") {
            setFilterSessions(sessions?.data.data.filter((session: any) => session.username.toLowerCase().includes(search.toLowerCase())))
        } else {
            setFilterSessions(sessions?.data.data)
        }
    }
    useEffect(() => {
        if (balances) {
            setFilterBalances(balances.data.data);
        }
    }, [balances])

    useEffect(() => {
        if (sessions) {
            setFilterSessions(sessions.data.data);
        }
    }, [sessions])

    const columnsSessions: ColumnInterface[] = [

        {
            title: "Utilisateur",
            key: "username",
            dataIndex: "username",
            className: "text-center"
        },
        {
            title: "Nombre de sessions",
            dataIndex: "nbSession",
            key: "nbSession",
            className: "text-center"
        },
        {
            title: "Type de connexion",
            key: "bindType",
            className: "text-center",
            render: (element: any) => <BadgeRounded text={element.bindType} className="bg-light" />
        }
    ];

    return (<div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>
        <Card title={"Balances"} className="max-h-96 overflow-y-auto" extras={[<input key={"search"} type={"text"} name="search" className={`form-control p-1`} placeholder={"Recherche..."} onChange={(e) => handleFilterBalance(e.target.value)} />]}>
            <div>
                <ul className="list-group list-group-flush">
                    {
                        isLoading ? (<>
                            <li>
                                <div className="py-2">
                                    <div className="bg-light mb-5 h-2 w-32 animate-pulse" />
                                    <ProgressBar className="animate-pulse" striped={true} value={60} />
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <div className="bg-light mb-5 h-2 w-32 animate-pulse" />
                                    <ProgressBar className="animate-pulse" striped={true} value={100} />
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <div className="bg-light mb-5 h-2 w-32 animate-pulse" />
                                    <ProgressBar className="animate-pulse" striped={true} value={100} />
                                </div>
                            </li>
                        </>) : (
                            <>
                                {
                                    filterBalances?.length > 0 ? filterBalances?.map((balance: any) => {
                                        return (
                                            <li key={balance.id}>
                                                <div className="py-2">
                                                    <div className="flex justify-between items-center">
                                                        <div className="flex flex-col">
                                                            <h5 className="font-bold">
                                                                {balance.country.name}
                                                            </h5>
                                                            <small className="">{balance.type}</small>
                                                        </div>
                                                        <div className="flex flex-col items-end">
                                                            <span className="font-bold">{balance.credit} SMS</span>
                                                            <span>{getPercent(balance.credit, balance.balance)}%</span>
                                                        </div>
                                                    </div>
                                                    {ChooseProgressBar(balance.credit, balance.balance)}
                                                </div>
                                            </li>
                                        );
                                    }) : (<li className="text-center">Aucune correspondance</li>)
                                }
                            </>
                        )
                    }
                </ul>
            </div>
        </Card>
        <Card title="Sessions actives" className="relative" extras={[<input key={"search"} type={"text"} name="search" className={`form-control p-1`} placeholder={"Recherche..."} onChange={(e) => handleFilterSession(e.target.value)} />]}>
            <Table columns={columnsSessions} loading={sessionsIsloading} datas={filterSessions} />
        </Card>
    </div>);
}

export default DashboardPage;
