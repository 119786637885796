import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EditButton, AddButton, DetailButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText, InputPassword, InputNumber, InputRadio, InputUrl } from "../components/form/Input";
import ModalDelete from "../components/modal/ModalDelete";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE, swal } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import { Select2, Select2Tag } from "../components/form/Select";
import Drawer from "../components/layout/Drawer";
import moment from "moment";
import { BadgeCircleCheck, BadgeRounded } from "../components/badge/Badge";
import Accordion from "../components/accordion/Accordion";
import SimpleButton from "../components/button/SimpleButton";
import BoxIcons from "../components/icons/BoxIcons";
import Dropdown from "../components/dropdown/Dropdown";
import { TRAFFICS } from "./message/configDatas";
import ToggleButton from "../components/button/ToggleButton";
import { CheckRole, UserRoles } from "../config/roles";
import { ColumnInterface } from "../components/table/typings";
import { ItemDropdownInterface } from "../components/dropdown/typings";
import TablePaginate from "../components/table/TablePaginate";


export default function UserPage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [paginationRechargeList, setPaginationRechargeList] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un user");
    const [actionForm, setActionForm] = useState(FormActionType.ADD);
    const [openModal, setOpenModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);
    const [openUpdateHostModal, setOpenUpdateHostModal] = useState(false);
    const [openRechargeBalance, setOpenRechargeBalance] = useState(false);
    const [openRechargeList, setOpenRechargeList] = useState(false);
    const isAdmin = CheckRole([UserRoles.ADMIN]);
    const isSupport = CheckRole([UserRoles.SUPPORT]);
    let queryKey = ['users', pagination.page, pagination.perPage];
    let queryKeyRechargeList = ['recharges', currentElement, pagination.page, pagination.perPage];
    const {
        register, setValue, reset, handleSubmit, control, formState: {
            errors,
        }
    } = useForm();

    const {
        register: registerUpdatePassword, reset: resetUpdatePassword, handleSubmit: handleSubmitUpdatePassword, formState: {
            errors: errorsUpdatePassword,
        }
    } = useForm();

    const {
        register: registerUpdateHost, control: controlUpdateHost, reset: resetUpdateHost, handleSubmit: handleSubmitUpdateHost, formState: {
            errors: errorsUpdateHost
        }
    } = useForm();

    const {
        register: registerRechargeBalance, control: controlRechargeBalance, reset: resetRechargeBalance, handleSubmit: handleSubmitRechargeBalance, formState: {
            errors: errorsRechargeBalance
        }
    } = useForm();

    const {
        data: users,
        isLoading
    } = useQueries(APP_API_URL + `users?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        data: rechargesList,
        refetch: refetchRechargeList,
        isLoading: isLoadingRechargeList
    } = useQueries(APP_API_URL + `recharges/${currentElement}?pageNo=${paginationRechargeList.page}&pageSize=${paginationRechargeList.perPage}`, queryKeyRechargeList, {
        enabled: false
    });

    const {
        data: roles,
        refetch: refetchRole,
    } = useQueries(APP_API_URL + `role-list`, ['roles-list'], {
        enabled: false
    });

    const {
        data: countries,
        refetch: refetchCountries,
    } = useQueries(APP_API_URL + `country-list`, ['countries-list'], {
        enabled: false
    });

    const {
        data: user,
        isLoading: refetchUserIsloading,
        refetch: refetchUser,
    } = useQueries(APP_API_URL + `user/${currentElement}`, ['user-detail', currentElement], {
        enabled: false
    });

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-store`, 'post', queryKey)

    const {
        mutateAsync: updateData,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `user/${currentElement}`, 'put', queryKey)

    const {
        mutateAsync: deleteElement,
        isLoading: elementDeleteIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `user/${currentElement}`, 'delete', queryKey)

    const {
        mutateAsync: generateAPIKey,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-apikey-generate/${currentElement}`, 'put', queryKey)

    const {
        mutateAsync: updatePassword,
        isLoading: updatePasswordIsLoading,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-update-password/${currentElement}`, 'put', queryKey)

    const {
        mutateAsync: updateHost,
        isLoading: updateHostIsLoading,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-whitelist-hosts`, 'post', queryKey)

    const {
        mutateAsync: updateSmppInbound,
        isLoading: updateSmppInboundIsLoading,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-smpp-inbound/${currentElement}`, 'put', queryKey)

    const {
        mutateAsync: rechargeBalance,
        isLoading: rechargeBalanceIsLoading,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-credit`, 'post', queryKey)

    //Functions
    const handleSubmitAddForm = (values: any) => {
        storeData({ ...values, smppInbound: values.smppInbound ?? true, sidVerify: values.sidVerify ?? true, maxSessionSize: parseInt(values.maxSessionSize), tps: parseInt(values.tps), roleId: values.roleId.id }).then(() => {
            reset();
            setOpenModal(false);
            toast.success('User ajouté');
        });
    };
    const handleSubmitEditForm = (values: any) => {
        updateData({ ...values, smppInbound: values.smppInbound ?? true, sidVerify: values.sidVerify ?? true, maxSessionSize: parseInt(values.maxSessionSize), tps: parseInt(values.tps), roleId: values.roleId.id }).then(() => {
            reset();
            setOpenModal(false);
            setCurrentElement(0);
            toast.success('User modifié');
        });
    };
    const handleSubmitDeleteElement = () => {
        deleteElement({}).then(() => {
            setCurrentElement(0)
            setOpenDeleteModal(false);
            toast.success('User supprimé');
        });
    };
    const handleCancelForm = () => {
        reset();
        resetUpdatePassword();
        resetUpdateHost();
        resetRechargeBalance();
        setCurrentElement(0);
        setOpenModal(false);
        setOpenUpdatePasswordModal(false);
        setOpenUpdateHostModal(false);
        setOpenRechargeBalance(false);
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un User");
        setActionForm(FormActionType.ADD);
        setCurrentElement(0);
        setOpenModal(true);
        reset();
    }

    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un User");
        setActionForm(FormActionType.EDIT);
        setValue('name', element.name)
        setValue('username', element.username)
        setValue('company', element.company)
        setValue('email', element.email)
        setValue('phone', element.phone)
        setValue('tps', element.tps)
        setValue('maxSessionSize', element.maxSessionSize)
        setValue('dlrUrl', element.dlrUrl)
        setValue('brokerCon', element.brokerCon)
        setValue('smppInbound', element.smppInbound)
        setValue('sidVerify', element.sidVerify)
        setValue('roleId', element.role)
        setCurrentElement(element.id)
        setOpenModal(true);
    }

    const handleDetailElement = (element: any) => {
        setCurrentElement(element.id)
        setActionForm(FormActionType.DETAIL)
    }

    const handleCloseDetailElement = () => {
        setOpenDetailModal(false);
        setCurrentElement(0);
        setActionForm(FormActionType.ADD);
    }

    const handleRechargeListElement = (element: any) => {
        setOpenRechargeList(true)
        setCurrentElement(element.id);
    }

    const handleCloseRechargeListElement = () => {
        setOpenRechargeList(false)
        setCurrentElement(0);
        setActionForm(FormActionType.ADD);
    }

    const handleGenerateApiKey = (element: any) => {
        setCurrentElement(element.id)
        swal.fire({
            text: "Confirmer la génération de l'api key!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: "Annuler",
            allowOutsideClick: false,
            width: 400,
        }).then((result) => {
            if (result.isConfirmed) {
                const toast_id = toast.loading("Génération de l'api key en cours....");
                generateAPIKey({}).then((response: any) => {
                    if (response.status === 200) {
                        toast.update(toast_id, { render: "L'api key a été généré avec succès!", type: "success", isLoading: false, autoClose: 3000 });
                    } else {
                        toast.update(toast_id, { render: "Une erreur est survenu lors de la génération de l'api key!", type: "error", isLoading: false, autoClose: 3000 });
                    }
                })
            }
        })
    }

    const handleUpdatePasswordElement = () => {
        resetUpdatePassword();
        setOpenUpdatePasswordModal(true);
    }

    const handleSubmitUpdatePasswordForm = (values: any) => {
        updatePassword({ password: values.newPassword }).then(() => {
            resetUpdatePassword();
            setOpenUpdatePasswordModal(false);
            toast.success('Mot de passe mis à jour');
        });
    };

    const handleUpdateHostElement = (data: any) => {
        resetUpdateHost();
        setOpenUpdateHostModal(true);
        setCurrentElement(data.username)
        //setValueUpdateHost('hosts', data.hosts.map((h: any) => { return { label: h.ip, value: h.ip } }))
    }

    const handleSubmitUpdateHostForm = (values: any) => {
        let newHosts = values.hosts.map((h: any) => { return h.value }).toString()
        updateHost({ username: currentElement, hosts: newHosts }).then((response: any) => {
            if (response && response.status === 200) {
                resetUpdateHost();
                toast.success('Ip whitelisté avec succès');
                setOpenUpdateHostModal(false);
            }
        });
    };

    const handleRechargeBalanceElement = (data: any) => {
        if (isAdmin || isSupport) {
            refetchCountries();
            isAdmin && refetchRole();
        }
        resetRechargeBalance();
        setCurrentElement(data.id)
        setOpenRechargeBalance(true);
    }

    const handleSubmitRechargeBalanceForm = (values: any) => {
        rechargeBalance({ uId: currentElement, credit: parseInt(values.credit), balanceType: values.balanceType.value, countryId: values.countryId.id, operation: "CREDIT", description: values.description }).then((response: any) => {
            if (response && response.data.status === "OK") {
                resetRechargeBalance();
                toast.success('Balance rechargée');
                setOpenRechargeBalance(false);
            } else {
                toast.error('Impossible de recharger la balance');
            }
        });
    };

    const handleSetSmppInbound = (smppInbound: boolean) => {
        const toast_id = toast.loading("Mise à jour en cours....");
        updateSmppInbound({ smppInBound: !smppInbound }).then((response: any) => {
            if (response && response.status === 200) {
                toast.update(toast_id, { render: "La mise à jour du status de la connexion SMPP avec succès!", type: "success", isLoading: false, autoClose: 3000 });
            } else {
                toast.update(toast_id, { render: "Une erreur est survenu lors de la mise à jour de la connexion SMPP!", type: "error", isLoading: false, autoClose: 3000 });
            }
        })
    }

    useEffect(() => {
        if (currentElement > 0 && actionForm === FormActionType.DETAIL) {
            setOpenDetailModal(true);
            refetchUser();
        }
    }, [currentElement, actionForm, refetchUser])

    useEffect(() => {
        if (openRechargeList) {
            refetchRechargeList();
        }
    }, [openRechargeList])

    useEffect(() => {
        refetchRole()
    }, [])

    const usersItems: ItemDropdownInterface[] = [
        {
            onClick: (data: any) => handleRechargeBalanceElement(data),
            className: '',
            children: () => (<span>Recharger le compte</span>)
        },
        {
            onClick: (data: any) => handleRechargeListElement(data),
            className: '',
            children: () => (<span>Liste des recharges</span>)
        },
        {
            onClick: (data: any) => handleUpdateHostElement(data),
            className: '',
            children: () => (<span>Whitelisté une IP</span>),
            isVisible: isAdmin
        },
        {
            onClick: () => handleUpdatePasswordElement(),
            className: '',
            children: () => (<span>Modifier le mot de passe</span>),
            isVisible: isAdmin
        }
    ]

    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "Nom",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Nom d'utilisateur",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Entreprise",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Role",
            key: "role",
            render: (element: any) => element.role.name,
            isVisible: isAdmin
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            key: "actions",
            className: "text-center",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    {isAdmin && (<SimpleButton onClick={() => handleGenerateApiKey(element)} className="bg-secondary rounded" title="Generate APi Key"><BoxIcons icon={"key"} /></SimpleButton>)}
                    <DetailButton value="" onClick={() => handleDetailElement(element)} />
                    {isAdmin && <EditButton value="" onClick={() => handleEditElement(element)} />}
                    <Dropdown dataToItem={element} buttonChildren={"Options"} onOpen={() => setCurrentElement(element.id)} buttonClassName="bg-soft-primary" items={usersItems} />
                </div>
            )
        }
    ];
    const columnsRecharge: ColumnInterface[] = [

        {
            title: "Pays",
            key: "country",
            render: (element: any) => element.country.name
        },
        {
            title: "Ancienne balance",
            dataIndex: "oldCredit",
            key: "oldCredit",
        },
        {
            title: "Crédit",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Nouvelle balance",
            dataIndex: "newCredit",
            key: "newCredit",
        },
        {
            title: "Type",
            dataIndex: "balanceType",
            key: "balanceType",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Date",
            key: "createdAt",
            render: (element: any) => moment(element.createdAt).format('YYYY-MM-DD H:mm:ss')
        }
    ];
    return (
        <>
            <CardTable extras={[(isAdmin && <AddButton key={"add"} onClick={handleAddElement}>Ajouter</AddButton>)]} columns={columns} title={"Users"}
                loading={isLoading} datas={users?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-left" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === 'add' ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal} >
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2"}>
                    <div className="col-span-2">
                        <InputText placeholder={"Entrer le nom"} label={"Noms"} register={register} error={errors?.name}
                            name={"name"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le username"} label={"Nom d'utilisateur"} register={register} error={errors?.username}
                            name={"username"} />
                    </div>
                    {
                        actionForm === 'add' && <div>
                            <InputPassword placeholder={"Entrer mot de passe"} label={"Mot de passe"}
                                register={register}
                                error={errors?.password}
                                name={"password"} />
                        </div>
                    }
                    <div>
                        <InputText placeholder={"Entrer le nom de l'entreprise"} label={"Entreprise"}
                            register={register} error={errors?.company}
                            name={"company"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer l'addresse email"} label={"Email"}
                            register={register} error={errors?.email}
                            name={"email"} />
                    </div>
                    <div>
                        <InputNumber placeholder={"Entrer le numero de telephone"} label={"Téléphone"}
                            register={register} error={errors?.phone}
                            name={"phone"} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le role"} label={"Role"}
                            register={register} error={errors?.roleId}
                            name={"roleId"} textKeyItem={"name"} valueKeyItem={"id"} items={roles?.data?.data} />
                    </div>
                    <div className="flex gap-2">
                        <div>
                            <InputNumber placeholder={"Entrer le nombre de TPS"} label={"TPS"}
                                register={register} error={errors?.tps}
                                name={"tps"} />
                        </div>
                        <div>
                            <InputNumber placeholder={"Entrer le nombre max de session"} label={"Max session"}
                                register={register} error={errors?.maxSessionSize}
                                name={"maxSessionSize"} />
                        </div>
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le type de connexion"} label={"Queue connexion"}
                            register={register} error={errors?.brokerCon} defaultValue={"broker.send.sms"}
                            name={"brokerCon"} />
                    </div>
                    <div className="col-span-2">
                        <InputUrl placeholder={"Entrer le lien du DLR"} label={"Lien du DLR"}
                            register={register} error={errors?.dlrUrl}
                            name={"dlrUrl"} />
                    </div>
                    <div>
                        <InputRadio control={control} label={"Connection par SMPP"}
                            register={register} error={errors?.smppInbound}
                            name={"smppInbound"} defaultValue={true} />
                    </div>
                    <div>
                        <InputRadio control={control} label={"Vérication SID"}
                            register={register} error={errors?.sidVerify}
                            name={"sidVerify"} defaultValue={true} />
                    </div>
                </div>
            </ModalForm>
            <ModalForm title={"Mettre à jour le mot de passe"} isLoading={updatePasswordIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitUpdatePassword(handleSubmitUpdatePasswordForm)}
                open={openUpdatePasswordModal} >
                <div>
                    <InputPassword placeholder={"Entrer le nouveau mot de passe"} label={"Nouveau mot de passe"}
                        register={registerUpdatePassword}
                        error={errorsUpdatePassword?.newPassword}
                        name={"newPassowrd"} />
                </div>
            </ModalForm>
            <ModalForm title={"Whitelisté une adresse IP"} isLoading={updateHostIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitUpdateHost(handleSubmitUpdateHostForm)}
                open={openUpdateHostModal} >
                <div>
                    <Select2Tag placeholder={"Entrer les adresses IP"} label={"Adresses IPs"}
                        register={registerUpdateHost} control={controlUpdateHost}
                        error={errorsUpdateHost?.hosts} multiple
                        name={"hosts"} textKeyItem={"id"} valueKeyItem={"value"} />
                </div>
            </ModalForm>
            <ModalForm title={"Recharge de compte"} isLoading={rechargeBalanceIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitRechargeBalance(handleSubmitRechargeBalanceForm)}
                open={openRechargeBalance} >
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2"}>
                    <div>
                        <Select2 control={controlRechargeBalance} placeholder={"Selectionner le pays"} label={"Pays"}
                            register={registerRechargeBalance} error={errorsRechargeBalance?.countryId}
                            name={"countryId"} textKeyItem={"name"} valueKeyItem={"id"} items={countries?.data?.data} />
                    </div>
                    <div>
                        <Select2 control={controlRechargeBalance} placeholder={"Selectionner le type"} label={"Type de balance"}
                            register={registerRechargeBalance} error={errorsRechargeBalance?.balanceType}
                            name={"balanceType"} textKeyItem={"label"} valueKeyItem={"value"} items={TRAFFICS} />
                    </div>
                    <div>
                        <InputNumber name={"credit"} label="Crédit" placeholder="Entrer le nombre de crédit" options={{
                            required: true,
                            min: 1
                        }} error={errorsRechargeBalance?.credit} register={registerRechargeBalance} />
                    </div>
                    <div>
                        <InputText placeholder={"Description"} label={"Description"} register={registerRechargeBalance} error={errorsRechargeBalance?.description}
                            name={"description"} />
                    </div>
                </div>
            </ModalForm>
            <ModalDelete isLoading={elementDeleteIsLoading} open={openDeleteModal} onClose={handleCancelForm}
                onConfirm={handleSubmitDeleteElement}>Souhaitez-vous vraiment supprimer l'utilisateur ?</ModalDelete>
            <Drawer title="Détail des informations du compte" open={openDetailModal} isLoading={refetchUserIsloading} setOpen={handleCloseDetailElement}>

                {
                    user ? (<div>
                        <Accordion title={"Informations sur l'utilisateur"} open={true}>
                            <table className="table w-full text-left">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>{user?.data.data.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Username</th>
                                        <td>{user?.data.data.username}</td>
                                    </tr>
                                    <tr>
                                        <th>Entreprise</th>
                                        <td>{user?.data.data.company}</td>
                                    </tr>
                                    <tr>
                                        <th>Téléphone</th>
                                        <td>{user?.data.data.phone}</td>
                                    </tr>
                                    <tr>
                                        <th>Role</th>
                                        <td>{user?.data.data.role.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Session Max</th>
                                        <td>{user?.data.data.maxSessionSize}</td>
                                    </tr>
                                    <tr>
                                        <th>Session ouverte(s)</th>
                                        <td>{user?.data.data.sessionSize}</td>
                                    </tr>
                                    <tr>
                                        <th>TPS</th>
                                        <td>{user?.data.data.tps}</td>
                                    </tr>
                                    <tr>
                                        <th>API Key</th>
                                        <td>{user?.data.data.apiKey}</td>
                                    </tr>
                                    <tr>
                                        <th>Activé</th>
                                        <td><BadgeCircleCheck active={user?.data.data.enabled} /></td>
                                    </tr>
                                    <tr>
                                        <th>IPs</th>
                                        <td>
                                            {user?.data.data.hosts.map((host: any, index: number) => {
                                                return (
                                                    <BadgeRounded key={"ip" + index} className="badge-soft-secondary mx-1" text={host.ip} />
                                                )
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Connexion SMPP</th>
                                        <td><ToggleButton confirmed={true} textConfirmation="Confirmer la modifcation du status de la connexion par SMPP" onClick={() => handleSetSmppInbound(user?.data.data.smppInbound)} enabled={user?.data.data.smppInbound} disabled={updateSmppInboundIsLoading} name={"tooggle"} />{/* <BadgeCircleCheck active={user?.data.data.smppInbound} /> */}</td>
                                    </tr>
                                    <tr>
                                        <th>Sender ID Vérifiés</th>
                                        <td><BadgeCircleCheck active={user?.data.data.sidVerify} /></td>
                                    </tr>
                                    <tr>
                                        <th>Date de création</th>
                                        <td>{moment(user?.data.data.createdAt).format('YYYY-MM-DD H:mm:ss')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Accordion>
                        <Accordion title={"Balances"}>
                            <table className="table w-full text-left">
                                <thead>
                                    <tr>
                                        <th>Pays</th>
                                        <th>Type</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user?.data.data.balances.map((balance: any, index: number) => {
                                        return (
                                            <tr key={"balance-line-" + index}>
                                                <td>{balance.country.name}</td>
                                                <td>{balance.type}</td>
                                                <td>{balance.credit}</td>
                                                <td>{balance.balance}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Accordion>
                    </div>) : (<div></div>)
                }
            </Drawer>
            <Drawer className="sm:w-[600px!important]" title="Listes des recharges" open={openRechargeList} isLoading={isLoadingRechargeList} setOpen={handleCloseRechargeListElement}>
                <div>
                    <TablePaginate columns={columnsRecharge} loading={isLoadingRechargeList} datas={rechargesList?.data} pagination={paginationRechargeList} setPagination={setPaginationRechargeList} />
                </div>
            </Drawer>
        </>
    );
}
