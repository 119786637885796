import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CardTable from "../../components/card/CardTable";
import { InputText, TextArea } from "../../components/form/Input";
import ModalForm from "../../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, PER_PAGE_VALUE } from "../../config/global";
import { APP_API_URL } from "../../utils/api";
import { useMutateWithInvalidateQueries } from "../../utils/mutation";
import { useQueries } from "../../utils/query";
import moment from "moment";
import SimpleButton from "../../components/button/SimpleButton";
import { Select2, Select2Tag } from "../../components/form/Select";
import { useAuth } from "../../hooks/useAuth";
import { TRAFFICS } from "./configDatas";
import { CheckRole, UserRoles } from "../../config/roles";
import { ColumnInterface } from "../../components/table/typings";
import { BadgeCircleCheck } from "../../components/badge/Badge";

export default function MessagePage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [openModal, setOpenModal] = useState(false);
    const { user: auth } = useAuth();
    const isAdmin = CheckRole([UserRoles.ADMIN]);
    const isSupport = CheckRole([UserRoles.SUPPORT]);
    let queryKey = ['messages', pagination.page, pagination.perPage];
    const {
        register, reset, setValue, control, handleSubmit, formState: {
            errors
        }
    } = useForm();
    const {
        data: messages,
        isLoading
    } = useQueries(APP_API_URL + `messages?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        data: services,
        refetch: refetchService,
    } = useQueries(APP_API_URL + `service-list`, ['services-list'], {
        enabled: false
    });
    const {
        mutateAsync: sendSMSData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `sendsms`, 'post', queryKey)

    //Functions
    const handleSubmitSendSMSForm = (values: any) => {
        let mobilesNumbers = values.mobileno.map((mobile: any) => { return mobile.value }).toString()
        sendSMSData({ ...values, mobileno: mobilesNumbers, serviceId: values.serviceId.id, traffic: values.traffic.value, apiKey: auth.user.api_key }).then((response: any) => {
            if (response.status === 200) {
                reset();
                setOpenModal(false);
                toast.success('Message envoyé');
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleSendSMS = () => {
        setOpenModal(true);
        reset();
        refetchService();
        setValue('traffic', TRAFFICS[0])
    }
    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "#",
            key: "index",
            render: (element, index) => <>{index+1}</>,
        },
        {
            title: "Téléphone",
            key: "phone",
            render: (element: any) => <span>{element.msisdn}</span>,
        },
        {
            title: "Sender",
            key: "sender",
            render: (element: any) => <span>{element.sender}</span>,
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Crédit",
            key: "creadit",
            dataIndex: "smsUnit",
        },
        {
            title: "Status",
            key: "status",
            render: (element: any) => (<span className={`badge ${element.status === "DELIVRD" ? "badge-soft-success" : element.status === 'SENT' ? "badge-soft-secondary" : "badge-soft-danger"}`}>{element.status}</span>),
        },
        {
            title: "DLR Sent",
            key: "dlrSent",
            render: (element: any) => (<BadgeCircleCheck active={element.dlrSent}  />),
        },
        {
            title: "Date d'envoi",
            key: "submitTime",
            render: (element: any) => <span>{element.submitTime ? moment(element.submitTime).format('YYYY-MM-DD H:mm:ss') : "----"}</span>,
        },
        {
            title: "Date de livraison",
            key: "deliveryTime",
            render: (element: any) => <span>{element.deliveryTime ? moment(element.deliveryTime).format('YYYY-MM-DD H:mm:ss') : "----"}</span>,
        },
        {
            title: "SMS ID",
            key: "id",
            render: (element: any) => <span>{element.id}</span>,
            isVisible: isAdmin
        },
        {
            title: "Service",
            key: "provider",
            render: (element: any) => <span>{element.service.name}</span>,
            isVisible: isAdmin
        },
        {
            title: "Utilisateur",
            key: "username",
            render: (element: any) => <span>{element.user.username}</span>,
            isVisible: isAdmin || isSupport
        },
        {
            title: "IP",
            key: "userIp",
            dataIndex: "userIp",
        },
        {
            title: "Mode",
            key: "smsMode",
            render: (element: any) => (<span className={`badge ${element.smsMode === "SMPP" ? "badge-soft-success" : "badge-soft-warning"}`}>{element.smsMode}</span>),
        },
        {
            title: "Provider ID",
            key: "providerMsgId",
            dataIndex: "providerMsgId",
            isVisible: isAdmin
        },
        {
            title: "Opérateur",
            key: "operator",
            render: (element: any) => <span>{element.operator.name}</span>,
        },
        {
            title: "Pays",
            key: "country",
            render: (element: any) => <span>{element.operator.country.name}</span>,
        },
    ];

    return (
        <>
            <CardTable extras={[(CheckRole([UserRoles.ADMIN]) && <SimpleButton className="btn soft-success rounded" onClick={handleSendSMS} key={"send"}>Envoyer un SMS</SimpleButton>)]} columns={columns} title={"Messages"}
                loading={isLoading} datas={messages?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center table-striped" />
            <ModalForm title={"Envoyer un SMS"} isLoading={storeDataIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(handleSubmitSendSMSForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le service"} label={"Service"}
                            register={register} error={errors?.serviceId}
                            name={"serviceId"} textKeyItem={"name"} valueKeyItem={"id"} items={services?.data?.data} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le traffic"} label={"Trafic"}
                            register={register} error={errors?.traffic}
                            name={"traffic"} textKeyItem={"label"} valueKeyItem={"value"} items={TRAFFICS} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le sender"} label={"Sender"} register={register}
                            error={errors?.sender}
                            name={"sender"} />
                    </div>
                    <div className="col-span-2">
                        <Select2Tag placeholder={"Entrer numero de telephone"} label={"Numeros de telephones"}
                            register={register}
                            error={errors?.mobileno}
                            multiple
                            control={control}
                            name={"mobileno"} textKeyItem={"label"} valueKeyItem={"value"} />
                    </div>
                    <div className="col-span-2">
                        <TextArea placeholder={"Entrer votre message"} label={"Message"}
                            register={register}
                            error={errors?.message}
                            name={"message"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
