import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EditButton, AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputRadio, InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import { Select2 } from "../components/form/Select";
import { TRAFFICS } from "./message/configDatas";
import { BadgeCircleCheck } from "../components/badge/Badge";
import { CheckRole, UserRoles } from "../config/roles";
import { ColumnInterface } from "../components/table/typings";
import useFileUpload from "../hooks/useFileUpload";

export default function Sender() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un sender");
    const [actionForm, setActionForm] = useState("add");
    const [openModal, setOpenModal] = useState(false);
    const [formWithFile, setFormWithFile] = useState(false);
    const { FileUploadView, file, reset: resetFileUpload } = useFileUpload({});
    const isAdmin = CheckRole([UserRoles.ADMIN]);
    const isSupport = CheckRole([UserRoles.SUPPORT]);
    let queryKey = ['senders', pagination.page, pagination.perPage];
    const apiUrlName = "senders";
    const {
        register, setValue, reset, control, handleSubmit, formState: {
            errors
        }
    } = useForm();
    const {
        data: senders,
        isLoading
    } = useQueries(APP_API_URL + apiUrlName + `?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        data: services,
        refetch: refetchService,
    } = useQueries(APP_API_URL + `service-list`, ['services-list'], {
        enabled: false
    });

    const {
        data: operators,
        refetch: refetchOperator,
    } = useQueries(APP_API_URL + `operator-list`, ['operators-list'], {
        enabled: false
    });

    const {
        data: users,
        refetch: refetchUser,
    } = useQueries(APP_API_URL + `user-list`, ['users-list'], {
        enabled: false
    });

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `${formWithFile ? 'sender-upload' : 'sender-store'}`, 'post', queryKey, {
        "Content-Type": "multipart/form-data"
    })

    const {
        mutateAsync: updateData,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `sender/${currentElement}`, 'put', queryKey)

    //Functions
    const handleSubmitAddForm = (values: any) => {
        const userId = isAdmin ? { userId: values.userId.id } : {};
        const serviceId = isAdmin ? { serviceId: values.serviceId?.id ?? 0 } : {};
        const operatorId = isAdmin ? { operatorId: values.operatorId?.id ?? 0 } : {};
        storeData({ ...values, file: file, traffic: values.traffic.value, ...userId, ...serviceId, ...operatorId, mtnV3Enabled: values.mtnV3Enabled ?? false }).then((response: any) => {
            if (response && response.data.status === 'OK') {
                reset();
                resetFileUpload();
                setOpenModal(false);
                toast.success('Sender ajouté');
            } else {
                toast.error('Impossible d\'ajouter un sender');
            }
        });
    };
    const handleSubmitEditForm = (values: any) => {
        const userId = isAdmin ? { userId: values.userId.id } : {};
        const serviceId = isAdmin ? { serviceId: values.serviceId?.id ?? null } : {};
        const operatorId = isAdmin ? { operatorId: values.operatorId?.id ?? null } : {};
        updateData({ ...values, traffic: values.traffic.value, ...userId, ...serviceId, ...operatorId, mtnV3Enabled: values.mtnV3Enabled ?? false }).then((response: any) => {
            if (response && response.data.status === 'OK') {
                reset();
                resetFileUpload();
                setOpenModal(false);
                toast.success('Sender ajouté');
                setCurrentElement(0)
            } else {
                toast.error('Impossible d\'ajouter un sender, il se pourrait qu\'il soit déjà enregistré!');
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        resetFileUpload();
        setCurrentElement(0);
        setOpenModal(false);
    };
    const handleAddElement = () => {
        setTitleForm("Ajouter un Sender");
        setActionForm("add");
        setCurrentElement(0);
        setOpenModal(true);
        setFormWithFile(false);
        reset();
        resetFileUpload();
        setValue('traffic', TRAFFICS[0])
    }
    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un Sender");
        setActionForm("edit");
        setValue('traffic', TRAFFICS.find((traffic) => traffic.value === element.traffic))
        setValue('name', element.name)
        setValue('serviceId', element.service)
        setValue('operatorId', element.operator)
        setValue('userId', element.user)
        setValue('mtnV3Enabled', element.mtnV3Enabled)
        setCurrentElement(element.id)
        setOpenModal(true);
    }

    useEffect(() => {
        if (isAdmin || isSupport) {
            refetchUser();
            if (isAdmin) {
                refetchService();
                refetchOperator();
            }
        }
    }, [refetchService, refetchOperator, refetchUser, isAdmin, isSupport])

    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "Utilisateur",
            key: "user",
            render: (element: any) => <span>{element.user.username}</span>,
            isVisible: isAdmin || isSupport
        },
        {
            title: "Nom",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Traffic",
            dataIndex: "traffic",
            key: "traffic",
        },
        {
            title: "Service",
            key: "service",
            render: (element: any) => <span>{element.service?.name}</span>
        },
        {
            title: "Opérator",
            key: "operator",
            render: (element: any) => <span>{element.operator?.name}</span>
        },
        {
            title: "MTN V3 ",
            key: "mtnV3Enabled",
            render: (element: any) => <BadgeCircleCheck active={element.mtnV3Enabled} />,
            isVisible: isAdmin
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    <EditButton value="" onClick={() => handleEditElement(element)} />
                </div>
            )
        }
    ];
    return (
        <>
            <CardTable extras={[<AddButton key={"add"} onClick={handleAddElement}>Ajouter</AddButton>]} columns={columns} title={"Sender"}
                loading={isLoading} datas={senders?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2 "}>
                    <div className="text-right col-span-2">
                        <input type={"checkbox"} onClick={() => setFormWithFile(!formWithFile)} id="check-file" className={``} />
                        <label htmlFor="check-file" className="ml-2">Ajouter à partir d'un fichier</label>
                    </div>
                    {
                        (isAdmin || isSupport) && (<div className="col-span-2">
                            <Select2 control={control} placeholder={"Selectionner de l'utilisateur"} label={"Utilisateur"}
                                register={register} error={errors?.userId}
                                name={"userId"} textKeyItem={"username"} valueKeyItem={"id"} items={users?.data?.data} />
                        </div>)
                    }
                    {
                        formWithFile ?
                            (
                                <div className="col-span-2">
                                    <FileUploadView name='file' accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' />
                                </div>
                            ) :
                            (
                                <div>
                                    <InputText placeholder={"Entrer le nom"} label={"Sender"} register={register} error={errors?.name}
                                        name={"name"} />
                                </div>
                            )
                    }
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le traffic"} label={"Trafic"}
                            register={register} error={errors?.traffic}
                            name={"traffic"} textKeyItem={"label"} valueKeyItem={"value"} items={TRAFFICS} />
                    </div>
                    {(isAdmin || isSupport) && (<><div>
                        <Select2 control={control} placeholder={"Selectionner le service"} label={"Service"}
                            register={register} error={errors?.serviceId} rules={{
                                required: false
                            }}
                            name={"serviceId"} textKeyItem={"name"} valueKeyItem={"id"} items={services?.data?.data} />
                    </div>
                        <div>
                            <Select2 control={control} placeholder={"Selection de l'opérateur"} label={"Opérateur"}
                                register={register} error={errors?.operatorId} rules={{
                                    required: false
                                }}
                                name={"operatorId"} textKeyItem={"name"} valueKeyItem={"id"} items={operators?.data?.data} />
                        </div></>)}
                    {isAdmin && (<div className="cols-span-2">
                        <InputRadio name={"mtnV3Enabled"} control={control} label="Activation MTN V3" register={register} error={errors?.mtnV3Enabled} />
                    </div>)}
                </div>
            </ModalForm>
        </>
    );
}
