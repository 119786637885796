import { Link, useLocation } from "react-router-dom";
import { MENUS } from "../../config/menus";
import BoxIcons from "../icons/BoxIcons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckRole } from "../../config/roles";

function SideNav() {
    const location = useLocation();
    const pathname = location.pathname.replace("/", "");
    const { t } = useTranslation();

    const ItemMenu = ({ menu }: { menu: any }) => {
        const [show, setShow] = useState(pathname === menu.pathname);
        if (menu.subMenus) {
            return (
                <li className={`${pathname === menu.pathname ? "mm-active" : ""}`}>
                    <button className={`hover:cursor-pointer has-arrow`} onClick={() => setShow(!show)}>
                        <BoxIcons icon={menu.icon} solid={menu.iconSolid} className={"mr-2"} /><span>{t(menu.title)}</span>
                    </button>
                    <ul className={`sub-menu ${show ? "mm-show" : "mm-collapse"}`}>
                        {menu.subMenus.map((subMenu: any, index: number) => {
                            return <li key={"sub-menu" + index}>
                                <Link to={subMenu.link}>
                                    <BoxIcons icon={subMenu.icon} solid={menu.iconSolid} className={"mr-2"} /><span>{t(subMenu.title)}</span>
                                </Link>
                            </li>
                        })}
                    </ul>
                </li>);
        } else {
            return (<li className={`${pathname === menu.pathname ? "mm-active" : ""}`}>
                <Link to={menu.link}>
                    <BoxIcons icon={menu.icon} solid={menu.iconSolid} className={"mr-2"} /><span>{t(menu.title)}</span>
                </Link>
            </li>)
        }
    }

    return (
        <div id="layout-wrapper">
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            {
                                MENUS.map((menu, index) => {
                                    return CheckRole(menu.roles) && (<ItemMenu menu={menu} key={"menu-" + index} />)
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideNav;
