import logo from "../../assets/images/logo-white.png"
import lang_en from "../../assets/images/flags/en.svg"
import lang_fr from "../../assets/images/flags/fr.svg"
import { useState } from "react";
import Dropdown from "../dropdown/Dropdown";
import DropdownGrid from "../dropdown/DropdownGrid";
import MDIcons from "../icons/MDIcons";
import FontAwesomeIcons from "../icons/FontAwesomeIcons";
import { AUTH_URL } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../../hooks/useLayout"
import BoxIcons from "../icons/BoxIcons"
import { LangType, LayoutType, SSO_ENABLE } from "../../config/global"
import i18n from "../../config/i18n"
import { getSessionLang, setSessionLang } from "../../utils/storage"
import { useAuth } from "../../hooks/useAuth";
import { DropdownPropsInterface, ItemDropdownInterface } from "../dropdown/typings";

export default function Header() {
    const [lang, setLang] = useState(getSessionLang() === LangType.fr ? lang_fr : lang_en);
    const { layout, changeLayout } = useLayout();
    const { user: auth, signOut } = useAuth();
    const navigate = useNavigate();
    const languageItems = [
        {
            onClick: () => handleChangeLanguage(LangType.fr, lang_fr),
            className: '',
            children: () => (
                <><img src={lang_fr} alt="user-french" className="mr-1" height="16" /> <span>Français</span></>
            )
        },
        {
            onClick: () => handleChangeLanguage(LangType.en, lang_en),
            className: '',
            children: () => (
                <><img src={lang_en} alt="user-english" className="mr-1" height="16" /> <span>English</span></>
            )
        }
    ]
    /* const appItems: ItemDropdownInterface[] = [
        {
            onClick: () => { },
            className: '',
            children: () => (<><FontAwesomeIcons icon={"sms"} className="text-blue-500 font-size-16" /><span>SMS</span></>)
        },
        {
            onClick: () => { },
            className: '',
            children: () => (
                <><FontAwesomeIcons icon={"money-bill-alt"} className="text-green-500 font-size-16" /><span>PAYMENT</span></>
            )
        },
        {
            onClick: () => { },
            className: '',
            children: () => (
                <><FontAwesomeIcons icon={"list-ol"} className="text-orange-500 font-size-16" /><span>USSD</span></>
            )
        },
        {
            onClick: () => { },
            className: '',
            children: () => (
                <><FontAwesomeIcons icon={"braille"} className="text-red-500 font-size-16" /><span>Short</span></>
            )
        }
    ] */
    const profileItems: ItemDropdownInterface[] = [
         {
            onClick: () => navigate("/profile"),
            className: '',
            children: () => (<><BoxIcons icon={"user"} className="font-size-16 align-middle mr-2" /><span>Profile</span></>)
        },
        /*{
            onClick: () => { },
            className: '',
            children: () => (<><BoxIcons icon={"wrench"} className="font-size-16 align-middle mr-2" />
                <span>Paramètres</span></>)
        }, */
        {
            onClick: () => {
                if (SSO_ENABLE) {
                    navigate("/logout", { replace: true });
                } else {
                    signOut();
                }
            },
            className: '',
            children: () => (<><BoxIcons icon={"power-off"} className="font-size-16 align-middle mr-2 text-red-500" /><span
                className="text-red-500">Déconnexion</span></>)
        },
    ]
    const layoutItems: ItemDropdownInterface[] = [
        {
            onClick: () => {},
            className: 'hover:bg-transparent hover:cursor-default',
            children: () => (<span className="ml-2">Menu</span>),
            divider: true
        }, {
            onClick: () => changeLayout(LayoutType.vertical),
            className: '',
            children: () => (
                <>{layout === LayoutType.vertical && <BoxIcons icon={"check"} />}
                    <span className="ml-2">Vertical</span></>
            )
        },
        {
            onClick: () => changeLayout(LayoutType.horizontal),
            className: '',
            children: () => (
                <>{layout === LayoutType.horizontal && <BoxIcons icon={"check"} />}
                    <span className="ml-2">Horizontal</span></>
            )
        }
    ]

    //Functions
    const handleOpenMenu = () => {
        if (layout === LayoutType.horizontal) {
            let topnav = document.getElementById("topnav-menu-content");
            if (topnav) {
                if (topnav.className.includes("show")) {
                    topnav.className = topnav.className.replace("show", "");
                } else {
                    topnav.className = topnav.className.concat("show");
                }
            }
        } else {
            if (document.body.className.includes("sidebar-enable")) {
                document.body.className = "";
            } else {
                document.body.className = "sidebar-enable"
            }
        }
    }

    const handleChangeLanguage = (lang: LangType, icon: any) => {
        i18n.changeLanguage(lang)
        setLang(icon)
        setSessionLang(lang)
    }
    return (<header id="page-topbar">
        <div className="navbar-header">
            <div className="flex justify-center items-center">
                <div className="navbar-brand-box">
                    <a href="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="" className="h-10" />
                        </span>
                        <span className="logo-lg">
                            <img src={logo} alt="" className="h-10" />
                        </span>
                    </a>
                </div>
                <button type="button" onClick={handleOpenMenu}
                    className="btn btn-sm px-3 font-size-16 lg:hidden header-item text-white">
                    <FontAwesomeIcons icon={"bars"} className={"fa-fw"} />
                </button>
            </div>
            <div className="flex justify-center items-center">
                <Dropdown buttonClassName={"header-item noti-icon"}
                    buttonChildren={<BoxIcons icon="layout" />}
                    items={layoutItems} />
                <Dropdown buttonClassName={"header-item noti-icon"}
                    buttonChildren={<img id="header-lang-img" src={lang} alt="Header Language" />}
                    items={languageItems} className={'language'} />
                {/* <DropdownGrid buttonChildren={<BoxIcons icon="customize" />}
                    items={appItems}
                    buttonClassName={"header-item noti-icon"}
                /> */}
                <Dropdown buttonClassName={"header-item noti-icon my-auto flex items-center"}
                    buttonChildren={<><span className="rounded-full header-profile-user bg-light inline-block" >{auth?.user.name[0]}</span>
                        <span className="hidden xl:inline-block ml-1"
                            key="username">{auth?.user.name} </span>
                        <MDIcons icon={"chevron-down"} className={"hidden xl:inline-block"} /></>}
                    items={profileItems} />

            </div>
        </div>
    </header>
    )
}
